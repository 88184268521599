@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
@font-face {
  font-family: "Inter";
  src: url(/static/media/Inter-Regular.a3e5baa2.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url(/static/media/Inter-Bold.1eca2d32.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Regular.9d883d54.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Bold.636be8de.ttf);
  font-weight: 700;
}

:root {
  --main-color: #174a84;
  --sub-color: #df691d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: unset;
  font-family: "Noto Sans JP", sans-serif;
}

.test {
  border: 1px solid red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:focus,
input:focus {
  outline: none;
}

::placeholder {
  color: #b9b9b9;
}

body {
  position: relative;
}

/* Select */
.MuiMenuItem-root.Mui-selected,
.MuiAutocomplete-option[aria-selected="true"] {
  background-color: var(--sub-color) !important;
  color: #ffffff !important;
}

.MuiButtonBase-root.MuiMenuItem-root.disabled-option {
  background-color: #ffffff;
  color: #ffffff !important;
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected.disabled-option {
  background-color: var(--sub-color) !important;
  color: var(--sub-color) !important;
}

.MuiAutocomplete-option {
  background-color: #ffffff !important;
}

.MuiAutocomplete-option[aria-selected="false"]:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: #000000 !important;
}

.MuiAutocomplete-inputFocused {
  font-family: "Inter" !important;
  font-weight: 700 !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--sub-color);
}

.MuiButtonBase-root.MuiMenuItem-root {
  font-weight: 700;
  font-size: 16px;
  font-family: "Inter";
}

.MuiList-root.MuiMenu-list {
  padding: 0;
}

button {
  cursor: pointer;
}

.MuiPickersToolbar-root {
  display: none !important;
}

.MuiPickersDay-root {
  border-radius: 50% !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--sub-color) !important;
}

.MuiPickersCalendarHeader-label {
  font-family: "Inter" !important;
  font-weight: 700 !important;
}

.MuiPickersDay-root {
  font-weight: 400 !important;
  font-family: "Inter" !important;
}

.MuiDialogActions-root {
  padding: 0 8px 8px;
  grid-gap: 8px;
  gap: 8px;
  display: flex;
  justify-content: flex-end;
}

.MuiDialogActions-root button {
  color: var(--sub-color) !important;
  font-family: "Inter";
  font-weight: 700;
  border: none;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.MuiDialog-container .MuiDialogActions-root button:nth-child(1) {
  background-color: var(--sub-color);
}

.MuiDialog-container .MuiDialogActions-root button {
  background-color: var(--main-color);
  color: #ffffff !important;
  font-size: 12px;
}

.PrivatePickersYear-root button.Mui-selected {
  background-color: var(--sub-color) !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrWeekDay {
  font-size: 14px;
}

.ant-picker-ok button.ant-btn-primary.ant-btn-sm {
  background-color: var(--main-color);
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter";
  box-shadow: none;
  border: none;
  color: #ffffff;
}

.ant-picker-time-panel-cell-inner,
.rdrDayNumber span,
.rdrMonthName,
.rdrYearPicker select {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
}

.ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--sub-color);
  color: #ffffff;
  border: none;
  box-shadow: none;
}

.ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  color: #000000e0;
}

.ant-picker-disabled {
  background-color: #ffffff !important;
}

.error {
  color: #ff0000 !important;
  font-family: "Inter" !important;
  margin-top: 5px !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

button.Mui-disabled,
button.disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

/* .MuiPaper-root.MuiPaper-elevation {
  margin-top: 8px;
  border-radius: 6px;
} */

.ant-select {
  border-bottom: 1px solid #bcbcbc;
  padding-bottom: 4px;
}

.select-custom.ant-select-dropdown {
  width: 58px !important;
  min-width: 58px !important;
  border-radius: 4px;
  border: 1px solid #707070;
  margin-top: 6px;
}

.select-custom .ant-select-item .ant-select-item-option-content .option-wrapper {
  padding: 0;
}

.select-custom .ant-select-item .option-wrapper {
  height: 100%;
}

.select-custom .ant-select-item .option-wrapper p {
  width: 100%;
  text-align: center;
  margin-left: 0 !important;
}

.select-custom .ant-select-item:first-child {
  border-radius: 0;
  border-color: #707070;
}

.select-custom .ant-select-item:first-child p {
  color: #174a84 !important;
}

.select-custom .ant-select-item:last-child p {
  color: #fc0d1b !important;
}

.select-custom .ant-select-item:last-child {
  border-bottom: none;
  color: #fc0d1b;
}

.ant-select-dropdown {
  padding: 0;
  width: 260px !important;
  min-width: 260px !important;
}

.ant-select-selector {
  border: none !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #ffffff !important;
  box-shadow: none !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: #ffffff !important;
}

.ant-select-item {
  background-color: #ffffff !important;
  padding: 0 !important;
  margin-bottom: 2px;
  border-bottom: 1px solid #bcbcbc;
}

.ant-select-item .ant-select-item-option-content .option-wrapper {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 3px 10px;
}

.ant-select-item .ant-select-item-option-content .option-wrapper p {
  margin-left: 6px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  color: #8b8b8b;
}

.ant-select-item .ant-select-item-option-state {
  display: none;
}

.ant-select-disabled .ant-select-selector {
  background-color: #ffffff !important;
}

.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #000000 !important;
}

input {
  outline: none;
}

.ant-picker-header-view,
.ant-picker-cell-inner,
.ant-picker-content tr th {
  font-family: "Inter";
}

.datetime-picker .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  border: 1px solid var(--sub-color) !important;
  background-color: var(--sub-color) !important;
}

.datetime-picker .ant-picker-cell-inner::before {
  border: none !important;
}

.ant-picker-header-view button {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  transform: translateY(2px);
}

.ant-picker-ok button {
  font-weight: 500;
  font-size: 12px;
  font-family: "Inter";
  box-shadow: none;
  border: none;
  color: #ffffff;
  background-color: var(--sub-color) !important;
}

.datetime-picker-start.datetime-picker.ant-picker-dropdown {
  top: 300px !important;
}

.disable-datetime-picker {
  display: none !important;

  .ant-picker-panel-container {
    display: none !important;
  }
}

.datetime-picker-end.datetime-picker.ant-picker-dropdown {
  top: 410px !important;
}

.datetime-picker.ant-picker-dropdown {
  left: 0 !important;
  right: 0;
  top: 380px !important;
}

.datetime-picker .ant-picker-panel-layout,
.datetime-picker .ant-picker-panel,
.datetime-picker .ant-picker-datetime-panel,
.datetime-picker .ant-picker-date-panel,
.datetime-picker .ant-picker-body,
.datetime-picker .ant-picker-content {
  width: 100% !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #df691d !important;
}

.test {
  border: 1px solid red !important;
}

.custom-notification {
  width: 310px;
  padding: 22px 24px !important;
}

.notification-info-success {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}

.notification-info-success p {
  color: #7b7b7b;
  font-family: "Inter";
  font-weight: 600;
  font-size: 18px;
}

.ant-picker-range-wrapper .ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none !important;
}
